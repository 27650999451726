import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from '@reach/router';
import { useBeta } from './beta';
import { Indexed } from '../types/indexed';
import LocalStorage from '../services/localStorage';
import { LLMId } from '../types/llm';
import { SpaceConfigInterface } from '../types/spaceConfig';
import { fallbackLLMId, PUBLIC_SPACE } from '../hooks/spaces';
import { GenerationConfigDTO } from '../types/prompt';

const LOCAL_STORAGE_CONFIG_SETTINGS_KEY = 'configSettings';

export interface SpaceConfigContext {
  currentSpaceId: string | null;
  currentLLMId: LLMId | null;
  configSettings: Indexed<ConfigSettingsItem>;
  setConfigSettings: (newConfigSettings: Indexed<ConfigSettingsItem>) => void;
  updateCurrentSpaceSettings: (newSpaceSettings: Partial<ConfigSettingsItem>) => void;
  updateCurrentLLMId: (newLLMId: LLMId) => void;
  currentSpaceSettings: ConfigSettingsItem | null;
  currentLLMSettings: LLMConfigSettingsItem | null;
  handleSpaceUpdate: (spaces: SpaceConfigInterface[]) => void;
}

export interface SpaceIdContext {
  currentSpaceId: string | null;
  handleSpaceUpdate: (spaces: SpaceConfigInterface[]) => void;
}

export interface ConfigSettingsItem {
  llm: LLMId;
  temperature: string;
  llmSettings?: {
    [llm: string]: LLMConfigSettingsItem;
  };
  private: boolean;
  public: boolean;
  inspirations: boolean;
}

export interface LLMConfigSettingsItem {
  multiModal?: GenerationConfigDTO;
}

const SpaceConfigContext = createContext<SpaceConfigContext>({
  currentSpaceId: null,
  currentLLMId: null,
  configSettings: {},
  setConfigSettings: () => {},
  updateCurrentSpaceSettings: () => {},
  updateCurrentLLMId: () => {},
  currentSpaceSettings: null,
  currentLLMSettings: null,
  handleSpaceUpdate: () => {},
});

// separate context to avoid rerenders for all components which only need the current space id
const SpaceIdContext = createContext<SpaceIdContext>({ currentSpaceId: null, handleSpaceUpdate: () => {} });

export function useSpaceConfig() {
  return useContext(SpaceConfigContext);
}

export function useSpaceId() {
  return useContext(SpaceIdContext);
}

export function SpaceConfigProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const { isBeta } = useBeta();
  const [configSettings, setConfigSettingsInternal] = useState<Indexed<ConfigSettingsItem>>(
    LocalStorage.get(LOCAL_STORAGE_CONFIG_SETTINGS_KEY) ?? {},
  );
  const currentSpaceId = getCurrentSpaceFromLocation(location.pathname, isBeta);
  const [currentLLMId, setCurrentLLMId] = useState<LLMId | null>(
    configSettings[currentSpaceId ?? '']?.llm ?? fallbackLLMId,
  );
  const currentSpaceSettings = configSettings[currentSpaceId ?? ''] ?? null;

  useEffect(() => {
    if (configSettings[currentSpaceId ?? '']?.llm) {
      updateCurrentLLMId(configSettings[currentSpaceId ?? '']?.llm);
    }
  }, [currentSpaceId]);

  const setConfigSettings = (
    newConfigSettingsOrFn:
      | Indexed<ConfigSettingsItem>
      | ((currentSettings: Indexed<ConfigSettingsItem>) => Indexed<ConfigSettingsItem>),
  ) => {
    setConfigSettingsInternal(currentConfigSettings => {
      const newConfigSettings =
        typeof newConfigSettingsOrFn === 'function'
          ? newConfigSettingsOrFn(currentConfigSettings)
          : newConfigSettingsOrFn;
      LocalStorage.set(LOCAL_STORAGE_CONFIG_SETTINGS_KEY, newConfigSettings);
      return newConfigSettings;
    });
  };

  const updateCurrentSpaceSettings = (newSpaceSettings: Partial<ConfigSettingsItem>) => {
    setConfigSettings(currentConfigSettings => ({
      ...currentConfigSettings,
      [currentSpaceId ?? '']: {
        ...currentConfigSettings[currentSpaceId ?? ''],
        ...newSpaceSettings,
      },
    }));
  };

  const updateCurrentLLMId = (newLLMId: LLMId) => {
    setCurrentLLMId(newLLMId);
  };

  const handleSpaceUpdate = useCallback(
    (spaces: SpaceConfigInterface[]) => {
      for (const space of spaces) {
        setConfigSettings(currentConfigSettings => ({
          ...currentConfigSettings,
          [space.id]: {
            ...(configSettings[space.id] ?? {}),
            llm:
              configSettings[space.id]?.llm ??
              space.config?.llms.find(
                e => e.isDefault && e.enabled && (!e.subscription || e.subscription.status === 'SUBSCRIBED'),
              )?.id ??
              fallbackLLMId,
            temperature:
              configSettings[space.id]?.temperature ?? (space.temperature?.defaultValue ? 'precise' : 'creative'),
          },
        }));

        // fall back to default llm if current llm is not available in the updated space
        const updatedCurrentSpace = spaces.find(e => e.id === currentSpaceId);
        if (
          // check for LLM id that will be used for a new chat
          updatedCurrentSpace &&
          !updatedCurrentSpace.config?.llms.find(
            e =>
              e.id === currentSpaceSettings?.llm &&
              e.enabled &&
              (!e.subscription || e.subscription.status === 'SUBSCRIBED'),
          )
        ) {
          updateCurrentSpaceSettings({
            llm:
              updatedCurrentSpace.config?.llms.find(
                e => e.isDefault && e.enabled && (!e.subscription || e.subscription.status === 'SUBSCRIBED'),
              )?.id ?? fallbackLLMId,
          });
        }
      }
    },
    [configSettings],
  );

  const value = useMemo(
    () => ({
      currentSpaceId,
      currentLLMId,
      configSettings,
      setConfigSettings,
      updateCurrentSpaceSettings,
      updateCurrentLLMId,
      currentSpaceSettings,
      currentLLMSettings: currentSpaceSettings?.llmSettings?.[currentLLMId ?? ''] ?? null,
      handleSpaceUpdate,
    }),
    [currentSpaceId, currentLLMId, configSettings, currentSpaceSettings],
  );

  const spaceIdValue = useMemo(() => ({ currentSpaceId, handleSpaceUpdate }), [currentSpaceId, handleSpaceUpdate]);

  return (
    <SpaceIdContext.Provider value={spaceIdValue}>
      <SpaceConfigContext.Provider value={value}>{children}</SpaceConfigContext.Provider>
    </SpaceIdContext.Provider>
  );
}

function getCurrentSpaceFromLocation(pathname: string, isBeta: boolean) {
  const spaceFromLocation = pathname.match(/\/space\/([^\/\s]+).*?$/);

  if (spaceFromLocation) {
    return spaceFromLocation[1];
  } else if (!isBeta) {
    return PUBLIC_SPACE;
  }

  return null;
}
